import { validateSSN, validateVerifySSN } from '../Validation';
import { Validator } from './Validator';

export const SSNValidators: Validator[] = [
    {
        func: validateSSN,
        message: 'Should be a valid SSN matching the XXX-XX-XXXX format'
    },
    {
        func: validateVerifySSN,
        message: 'Primary applicant and Co-applicant should not have same SSN'
    }
];
