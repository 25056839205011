import React from 'react';

import { Column, Row, TitleWrapper } from '../../components/shared.styled';
import { DateInputFormField, InputFormField, PhoneInputFormField, SelectFormField, SSNInputFormField } from '../../formFields';
import { Applicant } from '../../pages/PersonalInfo/models/Applicant.model';
import { Dispatcher } from '../Dispatcher';

type PersonalInfoFormProps = {
    title: string;
    applicant: Applicant;
    isJoint?: boolean;
    isPrimary?: boolean;
    applicantDispatcher: Dispatcher<Applicant>;
    className?: string;
};

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({
    title,
    applicant,
    applicantDispatcher,
    isJoint,
    isPrimary,
    className
}) => {
    const { firstName, lastName, email, verifyEmail, phone, dob, ssn, relationship, maritalStatus } = applicant;

    return (
        <div className={className}>
            {isJoint && <TitleWrapper>{title}</TitleWrapper>}
            <Row>
                <Column>
                    <InputFormField formField={firstName} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
                <Column>
                    <InputFormField formField={lastName} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputFormField formField={email} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
                <Column>
                    <InputFormField formField={verifyEmail} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <PhoneInputFormField formField={phone} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
                <Column>
                    <DateInputFormField formField={dob} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <SSNInputFormField formField={ssn} onChangeHandler={applicantDispatcher.updateInfo} />
                </Column>
                <Column>
                    {!isPrimary ? (
                        <SelectFormField formField={relationship} onChangeHandler={applicantDispatcher.updateSelectField} />
                    ) : (
                        <>
                            {/* This column creates intentional space until we can 
						supply some layout grid components in UI-bricks.*/}
                            <SelectFormField
                                id="applicant-maritalstatus"
                                formField={maritalStatus}
                                onChangeHandler={applicantDispatcher.updateSelectField}
                            />
                        </>
                    )}
                </Column>
            </Row>
            <Row>
                <Column>
                    {!isPrimary && applicant.relationship?.value !== '' && applicant.relationship?.value !== 'Spouse' ? (
                        <SelectFormField
                            id="coapplicant-maritalStatus"
                            formField={maritalStatus}
                            onChangeHandler={applicantDispatcher.updateSelectField}
                        />
                    ) : (
                        <>
                            {/* This column creates intentional space until we can supply 
                            some layout grid components in UI-bricks.*/}
                        </>
                    )}
                </Column>
                <Column>
                    <>
                        {/* This column creates intentional space until we can 
                        supply some layout grid components in UI-bricks.*/}
                    </>
                </Column>
            </Row>
        </div>
    );
};

export default PersonalInfoForm;
