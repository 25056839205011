import { FormField, Validator } from '../../../formValidator';
import { Clonable } from '../../../utils/Immer.class';
import { validateName } from '../../../utils/Validation';
import { Address } from './Address.model';

export class Spouse extends Validator implements Clonable<Spouse> {
    firstName: FormField.Input;
    lastName: FormField.Input;
    address: Address;
    spouseAddressSameAsPrimaryApplicant: FormField.Checkbox;

    constructor(me?: Spouse) {
        super();
        this.firstName =
            me?.firstName ??
            new FormField.Input({
                name: 'firstName',
                placeholder: 'John',
                label: 'First Name',
                optional: false,
                validationFunction: validateName,
                errorMessage: 'Letters, spaces and hyphens(-) only. Minimum 2 characters.',
                maxlength: 15,
                tooltip: 'The maximum length for this field is 15 characters'
            });
        this.lastName =
            me?.lastName ??
            new FormField.Input({
                name: 'lastName',
                placeholder: 'Smith',
                label: 'Last Name',
                optional: false,
                validationFunction: validateName,
                errorMessage: 'Letters, spaces and hyphens(-) only. Minimum 2 characters.',
                maxlength: 25,
                tooltip: 'The maximum length for this field is 25 characters'
            });
        this.spouseAddressSameAsPrimaryApplicant =
            me?.spouseAddressSameAsPrimaryApplicant ??
            new FormField.Checkbox({
                value: 'false',
                name: 'spouseAddressSameAsPrimaryApplicant',
                label: 'Is your address the same as the primary applicant',
                isChecked: false,
                errorMessage: '',
                validationFunction: () => true
            });
        this.address = me?.address ?? new Address();
        this.address.monthsAtAddress.optional = true;
        this.address.yearsAtAddress.optional = true;
    }

    clone = (): Spouse => {
        return new Spouse({ ...this });
    };
}
