import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { HorizontalRule } from '@makemydeal/ui-bricks/dist/cox';

import { submitIndividualApplicantPersonalInfo, submitJointApplicantPersonalInfo } from '../../actions/CreditAppMutators';
import { ButtonWrapper, DisclaimerText, PageContainer } from '../../components/shared.styled';
import SubmitButtonWithCheck, { ButtonState } from '../../components/SubmitLeadButton';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppDispatch, useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useSubmit } from '../../customHooks/UseSubmit.hook';
import { APPLICANT_CHOICE, PAGES } from '../../types/Constants';
import { KeyValuePair } from '../../types/KeyValuePair';
import { crossValidator, DOBValidators, SSNValidators } from '../../utils/crossValidation';
import { sendAccelerateCreditAppCoApplicantEmail, sendLead } from '../../utils/eventUtils';
import { isEmptyObject } from '../../utils/helper';
import { RelationshipTypes } from '../../utils/selectOptions';
import { validateVerifyEmail } from '../../utils/Validation';
import { Dispatcher } from '../Dispatcher';
import { Applicant } from './models/Applicant.model';
import PersonalInfoForm from './PersonalInfoForm';

const PersonalInfoPage: React.FC<{ location: Location }> = ({ location }) => {
    const creditAppState = useCreditAppState();
    const { isStandaloneExperience, isEmbeddedExperience } = useCreditAppExperience();
    const dispatch = useCreditAppDispatch();
    const history = useHistory();

    const [isJoint, setIsJoint] = useState(creditAppState.applicantChoice === APPLICANT_CHOICE.JOINT);
    const [submitLeadButtonState, setSubmitLeadButtonState] = React.useState(ButtonState.DEFAULT);

    const [primaryApplicant, setPrimaryApplicant] = React.useState(creditAppState.personalInfo?.primaryApplicant);
    const [jointApplicant, setJointApplicant] = React.useState(creditAppState.personalInfo?.jointApplicant);

    const primaryApplicantDispatcher = React.useMemo(() => new Dispatcher<Applicant>(setPrimaryApplicant), []);
    const jointApplicantDispatcher = React.useMemo(() => new Dispatcher<Applicant>(setJointApplicant), []);
    // has to be overwritten since the verifyEmail needs to know about the email value
    primaryApplicant.verifyEmail.validationFunction = (value: string) => validateVerifyEmail(value, primaryApplicant.email.value);
    jointApplicant.verifyEmail.validationFunction = (value: string) => validateVerifyEmail(value, jointApplicant.email.value);

    // ssn validation
    const validatePrimarySSN = crossValidator(SSNValidators, primaryApplicant.ssn.value, jointApplicant.ssn.value);
    const validateJointSSN = crossValidator(SSNValidators, jointApplicant.ssn.value, primaryApplicant.ssn.value);
    primaryApplicant.ssn.validationFunction = () => validatePrimarySSN.length === 0;
    primaryApplicant.ssn.errorMessage = validatePrimarySSN.length > 0 ? validatePrimarySSN[0] : '';
    jointApplicant.ssn.validationFunction = () => validateJointSSN.length === 0;
    jointApplicant.ssn.errorMessage = validateJointSSN.length > 0 ? validateJointSSN[0] : '';

    // dob validation
    const validatePrimaryDOB = crossValidator(DOBValidators, primaryApplicant.dob.value, jointApplicant.dob.value);
    const validateJointDOB = crossValidator(DOBValidators, jointApplicant.dob.value, primaryApplicant.dob.value);
    primaryApplicant.dob.validationFunction = () => validatePrimaryDOB.length === 0;
    primaryApplicant.dob.errorMessage = validatePrimaryDOB.length > 0 ? validatePrimaryDOB[0] : '';
    jointApplicant.dob.validationFunction = () => validateJointDOB.length === 0;
    jointApplicant.dob.errorMessage = validateJointDOB.length > 0 ? validateJointDOB[0] : '';

    // This avoids validation error on the primaryApplicant.relationship field
    React.useEffect(() => {
        primaryApplicant.relationship.optional = true;
        setPrimaryApplicant(primaryApplicant);
    }, [primaryApplicant, primaryApplicant.relationship.optional]);

    React.useEffect(() => {
        const jointIsSpouse = jointApplicant.relationship.value === RelationshipTypes.Spouse;
        const jointMaritalStatusIsOptional = jointApplicant.maritalStatus.optional;
        if (jointIsSpouse && !jointMaritalStatusIsOptional) {
            setJointApplicant((jointApplicant) => {
                jointApplicant.maritalStatus.optional = true;
                return { ...jointApplicant };
            });
        }
        if (!jointIsSpouse && jointMaritalStatusIsOptional) {
            setJointApplicant((jointApplicant) => {
                jointApplicant.maritalStatus.optional = false;
                return { ...jointApplicant };
            });
        }
    }, [jointApplicant.maritalStatus.optional, jointApplicant.relationship.value]);

    React.useEffect(() => {
        setIsJoint(creditAppState.applicantChoice === APPLICANT_CHOICE.JOINT);
    }, [creditAppState.applicantChoice]);

    const onSuccess = () => {
        sendLead({
            firstName: primaryApplicant.firstName.getTrimmed() ?? '',
            lastName: primaryApplicant.lastName.getTrimmed() ?? '',
            phone: primaryApplicant.phone.getTrimmed() ?? '',
            email: primaryApplicant.email.getTrimmed() ?? ''
        });
        history.push(PAGES['/housing'].urlPath);
    };

    const SubmitIndividual = (): any => {
        const statePayload = {
            primaryApplicant: {
                state: primaryApplicant,
                setter: setPrimaryApplicant
            }
        };
        return useSubmit(
            dispatch,
            submitIndividualApplicantPersonalInfo,
            statePayload,
            undefined,
            location,
            isStandaloneExperience
        );
    };

    const SubmitJoint = (): KeyValuePair => {
        const stateModel = {
            primaryApplicant: {
                state: primaryApplicant,
                setter: setPrimaryApplicant
            },
            jointApplicant: {
                state: jointApplicant,
                setter: setJointApplicant
            }
        };
        return useSubmit(dispatch, submitJointApplicantPersonalInfo, stateModel, undefined, location, isStandaloneExperience);
    };

    const Submit = () => {
        let errors: KeyValuePair;
        if (isJoint) errors = SubmitJoint();
        else errors = SubmitIndividual();
        if (isEmptyObject(errors)) {
            const coApplicantEmail = creditAppState.personalInfo?.jointApplicant?.email.value;
            if (coApplicantEmail) {
                sendAccelerateCreditAppCoApplicantEmail(coApplicantEmail);
            }
            isEmbeddedExperience ? setSubmitLeadButtonState(ButtonState.SENDING) : history.push(PAGES['/housing'].urlPath);
        }
    };

    return (
        <PageContainer>
            <PersonalInfoForm
                title="Primary Applicant"
                className="primary-applicant"
                applicant={primaryApplicant as Applicant}
                applicantDispatcher={primaryApplicantDispatcher}
                isJoint={isJoint}
                isPrimary={true}
            />
            {isJoint && (
                <>
                    <HorizontalRule />
                    <PersonalInfoForm
                        title="Co-Applicant"
                        className="co-applicant"
                        applicant={jointApplicant as Applicant}
                        applicantDispatcher={jointApplicantDispatcher}
                        isJoint={isJoint}
                        isPrimary={false}
                    />
                </>
            )}
            <DisclaimerText>
                By completing this application you authorize us to obtain and verify information about you including access to your
                credit reports.
            </DisclaimerText>
            {isEmbeddedExperience ? (
                <SubmitButtonWithCheck onClick={Submit} state={submitLeadButtonState} onSuccess={onSuccess} label="Next" />
            ) : (
                <ButtonWrapper onClick={Submit}>Next</ButtonWrapper>
            )}
        </PageContainer>
    );
};
export default WithPageUI(PersonalInfoPage);
