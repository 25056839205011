import { rem } from 'polished';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@makemydeal/ui-bricks/dist/cox';

import { Column, CommonHeader } from '../../components/shared.styled';
import { devices } from '../../utils/deviceUtils';

export const EditIconContainer = styled.span`
	display: flex;
	align-items: center;
	margin-left: ${rem(11)};
	font-size: ${rem(19)};

		&:hover .primary-link {
			color: ${({ theme }) => theme.primaryLinkHoverColor};
			text-decoration: ${({ theme }) => theme.primaryLinkHoverTextDecoration};
		}

		&:hover .primary-link-icon {
			color: ${({ theme }) => theme.primaryLinkHoverColor};
		}
	}
`;

export const StyledFontIcon = styled(FontAwesomeIcon)`
    font-weight: 'bold';
    color: ${({ theme }) => theme.primaryLinkColor};
`;

export const StyledEditLink = styled(Link)`
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 0 ${rem(-5)};
    background-color: ${({ theme }) => theme.creditAppEditButtonBackgroundColor};
    border: none;
    margin-left: ${rem(3)};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    @media ${devices.tablet} {
        justify-content: space-between;
    }
`;

export const Header = styled(CommonHeader)`
    font-size: ${rem(24)};
`;

export const ContentWrapper = styled.div`
    margin: ${rem(10)} 0 0 0;
`;

export const InfoCardLabel = styled(Column)`
    color: ${({ theme }) => theme.creditAppInfoCardLabelColor};
    font-weight: 400;
`;

export const InfoCardDetails = styled(Column)`
    color: ${({ theme }) => theme.creditAppInfoCardDetailsColor};
    font-weight: ${({ theme }) => theme.creditAppInfoCardDetailsFontWeight};
`;
