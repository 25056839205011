/* eslint-disable max-len */
import { ADOBE_STANDALONE_TAGS, AdobeStandaloneTag } from './AdobeStandaloneTags';
import { ADOBE_TAGS, AdobeTag } from './AdobeTags';
// TODO: Cleanup constants around Decision data

const PageConfiguration = {
    '/': {
        name: 'Landing',
        urlPath: '/',
        hasStepper: false,
        pageClass: 'credit-app-landing-page',
        pageTitle: 'Apply For Credit Online',
        pageSubTitle:
            "This application should only take about 10 minutes. Don't worry, we'll only use this to process your application.",
        creditDecisionPageSubTitle: 'Receive an instant decision to your credit application within 60 seconds.',
        adobeClickedCTA: ADOBE_TAGS.DR_CREDIT_APP_DISPLAYED,
        adobeStandaloneClickedCTA: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_STARTED,
        adobeStandaloneDisplayed: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_DISPLAYED
    },
    '/personal': {
        name: 'Personal',
        urlPath: '/personal',
        hasStepper: true,
        pageClass: 'credit-app-joint-personal-info-page',
        pageTitle: 'Personal Information',
        pageSubTitle: 'We need a few details about you to start your application.',
        adobeClickedCTA: ADOBE_TAGS.DR_CREDIT_APP_PERSONAL_CTA_CLICKED,
        adobeStandaloneClickedCTA: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_PERSONAL_CTA_CLICKED,
        adobeDisplayed: ADOBE_TAGS.DR_CREDIT_APP_PERSONALINFO_PAGE_DISPLAYED,
        adobeStandaloneDisplayed: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_PERSONALINFO_PAGE_DISPLAYED,
        adobeEditNavigation: ADOBE_TAGS.DR_CREDIT_APP_EDIT_PERSONAL_CLICKED,
        adobeStandaloneEditNavigation: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_EDIT_PERSONAL_CLICKED,
        adobeStepperNavigation: ADOBE_TAGS.DR_CREDIT_APP_PERSONALINFO_RETURN_CLICKED,
        adobeStandaloneStepperNavigation: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_PERSONALINFO_RETURN_CLICKED
    },
    '/housing': {
        name: 'Housing',
        urlPath: '/housing',
        hasStepper: true,
        pageClass: 'credit-app-joint-housing-info-page',
        pageTitle: 'Housing',
        pageSubTitle: 'For your protection, we will be looking at your recent history to verify your identity.',
        adobeClickedCTA: ADOBE_TAGS.DR_CREDIT_APP_HOUSING_CTA_CLICKED,
        adobeStandaloneClickedCTA: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_HOUSING_CTA_CLICKED,
        adobeDisplayed: ADOBE_TAGS.DR_CREDIT_APP_HOUSING_PAGE_DISPLAYED,
        adobeStandaloneDisplayed: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_HOUSING_PAGE_DISPLAYED,
        adobeEditNavigation: ADOBE_TAGS.DR_CREDIT_APP_EDIT_HOUSING_CLICKED,
        adobeStandaloneEditNavigation: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_EDIT_HOUSING_CLICKED,
        adobeStepperNavigation: ADOBE_TAGS.DR_CREDIT_APP_HOUSING_RETURN_CLICKED,
        adobeStandaloneStepperNavigation: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_HOUSING_RETURN_CLICKED
    },
    '/employment': {
        hasStepper: true,
        name: 'Income',
        urlPath: '/employment',
        pageClass: 'credit-app-joint-employment-info-page',
        pageTitle: 'Income',
        pageSubTitle: 'For your protection, we will be looking at your recent history to verify your identity.',
        adobeClickedCTA: ADOBE_TAGS.DR_CREDIT_APP_EMPLOYMENT_CTA_CLICKED,
        adobeStandaloneClickedCTA: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_EMPLOYMENT_CTA_CLICKED,
        adobeDisplayed: ADOBE_TAGS.DR_CREDIT_APP_EMPLOYMENT_INFO_DISPLAYED,
        adobeStandaloneDisplayed: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_EMPLOYMENT_DISPLAYED,
        adobeEditNavigation: ADOBE_TAGS.DR_CREDIT_APP_EDIT_EMPLOYMENT_CLICKED,
        adobeStandaloneEditNavigation: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_EDIT_EMPLOYMENT_CLICKED,
        adobeStepperNavigation: ADOBE_TAGS.DR_CREDIT_APP_EMPLOYMENT_RETURN_CLICKED,
        adobeStandaloneStepperNavigation: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_EMPLOYMENT_RETURN_CLICKED
    },
    '/review': {
        hasStepper: true,
        name: 'Review',
        urlPath: '/review',
        pageClass: 'credit-app-individual-review-page',
        pageTitle: 'Review and Submit',
        pageSubTitle: 'Take one last look and make changes as need before submitting you application.',
        adobeDisplayed: ADOBE_TAGS.DR_CREDIT_APP_REVIEW_PAGE_DISPLAYED,
        adobeStandaloneDisplayed: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_REVIEW_PAGE_DISPLAYED,
        adobeStandaloneClickedCTA: ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_SUBMIT_CTA_CLICKED,
        adobeClickedCTA: ADOBE_TAGS.DR_CREDIT_APP_SUBMIT_CTA_CLICKED
    },
    '/confirmation': {
        hasStepper: false,
        name: 'Confirmation',
        urlPath: '/confirmation',
        pageClass: 'credit-app-confirmation-page',
        pageTitle: 'Your Application is Complete!',
        pageSubTitle: "Great work! That's one less thing to do at the dealership."
    },
    '/confirmationStandAlone': {
        hasStepper: false,
        name: 'ConfirmationStandAlone',
        urlPath: '/confirmationStandAlone',
        pageClass: 'credit-app-confirmation-page',
        pageTitle: 'Your Application is Complete!',
        pageSubTitle:
            "Great work! That's one less thing to do at the dealership. Once you save this information for your records, you can close this page."
    },
    '/decision': {
        name: 'CreditDecision',
        urlPath: '/decision',
        hasStepper: false,
        pageClass: 'credit-decision-landing-page',
        pageTitle: undefined,
        pageSubTitle: undefined,
        adobeDisplayed: ADOBE_TAGS.DR_CREDIT_APP_DECISION_PAGE_DISPLAYED
    }
};

export type URLPaths = keyof typeof PageConfiguration;

type PageData = {
    [prop in URLPaths]: {
        name: string;
        urlPath: string;
        hasStepper: boolean;
        pageClass: string;
        pageTitle?: string;
        pageSubTitle?: string;
        adobeDisplayed?: AdobeTag;
        adobeStandaloneDisplayed?: AdobeStandaloneTag;
        adobeClickedCTA?: AdobeTag;
        adobeStandaloneClickedCTA?: AdobeStandaloneTag;
        adobeStepperNavigation?: AdobeTag;
        adobeStandaloneStepperNavigation?: AdobeStandaloneTag;
        adobeEditNavigation?: AdobeTag;
        adobeStandaloneEditNavigation?: AdobeStandaloneTag;
        creditDecisionPageSubTitle?: string;
    };
};

export const APPLICANT_CHOICE = {
    INDIVIDUAL: 'Individual',
    JOINT: 'Joint'
};

export const PAGES = PageConfiguration as PageData;

export const CREDIT_DECISION_TYPES = {
    APPROVED_OR_CONDITIONALLY_APPROVED: 'APPROVED_OR_CONDITIONALLY_APPROVED',
    PENDING: 'PENDING'
} as const;

export const DEALER_DECISION_TYPES = {
    APPROVED: 'Approved',
    CONDITIONALLY_APPROVED: 'ConditionallyApproved',
    DECLINED: 'Declined',
    PENDING: 'Pending'
} as const;

export const APPROVED_DECISION_TYPES = [
    CREDIT_DECISION_TYPES.APPROVED_OR_CONDITIONALLY_APPROVED,
    DEALER_DECISION_TYPES.APPROVED,
    DEALER_DECISION_TYPES.CONDITIONALLY_APPROVED
];

export const OFFER_TYPES = {
    FINANCE: 'finance',
    LEASE: 'lease'
};

export const lenderCardTitles: any = {
    lenderName: 'Lender',
    type: 'Type',
    sellRate: 'Rate',
    dealComments: 'Dealer Comments',
    term: 'Term',
    amount: 'Amount',
    dueSigning: 'Due at Signing',
    status: 'Status',
    vehicle: 'Vehicle',
    disclaimers: 'Disclamiers'
};

type CreditDecisionType = typeof CREDIT_DECISION_TYPES[keyof typeof CREDIT_DECISION_TYPES];

type DealerDecisionType = typeof DEALER_DECISION_TYPES[keyof typeof DEALER_DECISION_TYPES];

export type DecisionType = CreditDecisionType | DealerDecisionType;

export const CREDIT_DECISION_DATA = {
    // Lender Decision Statuses (from DT)
    APPROVED_OR_CONDITIONALLY_APPROVED: {
        pageType: 'APPROVED_OR_CONDITIONALLY_APPROVED',
        pageTitle: "You're One Step Closer to Your New Car!",
        pageTitleDesc1: undefined,
        pageTitleDesc2: undefined,
        cardHeaderText1: 'Your application has been ',
        cardHeaderText2: 'Approved',
        cardDetailText1: 'Your sales representative will reach out to discuss your loan options or you can call us directly at ',
        cardDetailText2: undefined
    },
    PENDING: {
        pageType: 'PENDING',
        pageTitle: 'Application Complete',
        pageTitleDesc1: 'You will receive an email if a new credit decision becomes available.',
        pageTitleDesc2: undefined,
        cardHeaderText1: 'Your application is',
        cardHeaderText2: 'Pending',
        cardDetailText1: 'Your sales representative will reach out to discuss your loan options or you can call us directly at ',
        cardDetailText2: undefined
    },

    // Dealer Decision Statuses (from CMD)
    Approved: {
        pageType: 'Approved',
        pageTitle: 'Congratulations! You have been approved. ',
        pageTitleDesc1: undefined,
        pageTitleDesc2: undefined,
        cardHeaderText1: 'Application Status',
        cardHeaderText2: 'Approved',
        cardDetailText1: 'You have been approved for vehicle financing. Please contact us at ',
        cardDetailText2: ' to review specific vehicle financing terms and conditions.'
    },
    ConditionallyApproved: {
        pageType: 'ConditionallyApproved',
        pageTitle: 'Congratulations! You have been conditionally approved.',
        pageTitleDesc1: undefined,
        pageTitleDesc2: undefined,
        cardHeaderText1: 'Application Status',
        cardHeaderText2: 'Conditionally Approved',
        cardDetailText1: 'You have been conditionally approved for vehicle financing. Please contact us at ',
        cardDetailText2: ' to review specific vehicle financing terms and conditions of approval.'
    },
    Pending: {
        pageType: 'Pending',
        pageTitle: 'Your application needs further attention.',
        pageTitleDesc1: undefined,
        pageTitleDesc2: undefined,
        cardHeaderText1: 'Application Status',
        cardHeaderText2: 'Pending',
        cardDetailText1:
            'Your application has been submitted but it looks like some additional information may be required before providing a decision. Your sales representative will contact you shortly or you can call us at ',
        cardDetailText2: undefined
    },
    Declined: {
        pageType: 'Declined',
        pageTitle: 'Your application needs further attention.',
        pageTitleDesc1: undefined,
        pageTitleDesc2: undefined,
        cardHeaderText1: 'Application Status',
        cardHeaderText2: 'Needs Attention',
        cardDetailText1:
            'Your application has been submitted but it looks like some additional information may be required before providing a decision. Your sales representative will contact you shortly or you can call us at ',
        cardDetailText2: undefined
    }
};

export const DECISION_APPROVED_HEADER = 'Approved';
export const DECISION_CONDITIONALLY_APPROVED_HEADER = 'Conditionally Approved';
export const DECISION_PENDING_HEADER = 'Pending';
export const DECISION_DECLINED_HEADER = 'Needs Attention';

export const DECISION_TYPE_HEADER_MAP = {
    [DEALER_DECISION_TYPES.APPROVED]: DECISION_APPROVED_HEADER,
    [CREDIT_DECISION_TYPES.APPROVED_OR_CONDITIONALLY_APPROVED]: DECISION_APPROVED_HEADER,
    [DEALER_DECISION_TYPES.CONDITIONALLY_APPROVED]: DECISION_CONDITIONALLY_APPROVED_HEADER,
    [DEALER_DECISION_TYPES.PENDING]: DECISION_PENDING_HEADER,
    [CREDIT_DECISION_TYPES.PENDING]: DECISION_PENDING_HEADER,
    [DEALER_DECISION_TYPES.DECLINED]: DECISION_DECLINED_HEADER
} as const;

export const CREDIT_DECISION_TIMERS = {
    INACTIVE_USER_LIMIT: 120,
    DECISION_REQUEST_TIMER: 60_000,
    DECISION_REQUEST_INTERVAL: 5_000
};

export const tabletWidth = 796;

export const CreditAppExperiences = {
    Embedded: 'Embedded',
    Standalone: 'Standalone'
};

export const ADOBE_DATA_LAYER_NAME = 'scaDataLayer';

export const DEAL_XG_WAIT_TIME = 60 * 1000;

export const TERMS_AND_CONDITIONS_HEADER = 'Important Terms & Conditions';
export const TERMS_AND_CONDITIONS_CONTENT =
    'You have been approved for financing by our dealership based on your consumer report and the information you provided in your credit application. This approval is conditioned on your continuing to maintain your credit standards. This approval will expire in 30 days. Please contact us to review specific vehicle financing terms and conditions from our dealership.';
