import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Spinner } from '@makemydeal/ui-bricks/dist/cox';

import { PageContainer, SpinnerContainer } from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { generatePayload } from '../../integration/FD-v2.0/Adapter';
import { ADOBE_STANDALONE_TAGS } from '../../types/AdobeStandaloneTags';
import { ADOBE_TAGS } from '../../types/AdobeTags';
import { CreditAppExperiences, DEAL_XG_WAIT_TIME, PAGES } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import {
    constructSCADataLayer,
    sendAccelerateCreditAppId,
    sendAccelerateInfoRecordUpdateStatus,
    sendAdobeEvents,
    sendCreditAppId,
    sendLeadAndCreditApp
} from '../../utils/eventUtils';
import { getLambdaUrl } from '../../utils/externalUrls';
import { emptyString, getAccelerateDealerId, sanitizeStrNum } from '../../utils/helper';
import { getUrlVars } from '../../utils/urlParameters';
import ConfirmationContainer from './ConfirmationContainer';

const ConfirmationPage: React.FC<{ location?: Location }> = ({ location }) => {
    const creditAppState = useCreditAppState();
    const externalResourcesState = useExternalResourceState();
    const params = getUrlVars();
    const isEmbedded = !emptyString(params.sessionId);
    const history = useHistory();
    const { isEmbeddedExperience, isStandaloneExperience } = useCreditAppExperience();

    const correlationId = (externalResourcesState as Resources)['x-coxauto-correlation-id'];
    const componentId = (externalResourcesState as Resources)['coxauto:ci-id'];
    const connectionId = (externalResourcesState as Resources)?.offer?.connectionId;
    const vin = (externalResourcesState as Resources)?.offer?.vin;
    const dealXgId = (externalResourcesState as Resources)?.dealXgId;
    const vehicleCondition = (externalResourcesState as Resources)?.offer?.vehicleCondition;
    const isCreditDecisionEnabled = (externalResourcesState as Resources)?.dealer?.isAccelerateCreditDecisionEnabled;
    const enableCreditAppInfoRecord = (externalResourcesState as Resources)?.toggles?.toggles?.enableCreditAppInfoRecord;
    const enableCreditDecisionDetails = (externalResourcesState as Resources)?.toggles?.toggles?.enableCreditDecisionDetails;
    const isAioDxgScaLeadEnabled = (externalResourcesState as Resources)?.toggles?.toggles?.isAioDxgScaLeadEnabled;
    const toggleFsDealRefId = (externalResourcesState as Resources)?.toggles?.toggles?.toggleFsDealRefId;
    const enableSponsorQueryParam = (externalResourcesState as Resources)?.toggles?.toggles?.enableSponsorQueryParam;
    const enableLoggingDecisionPayload = (externalResourcesState as Resources)?.toggles?.toggles?.enableLoggingDecisionPayload;
    const enableLambdaGetOffer = (externalResourcesState as Resources)?.toggles?.toggles?.enableLambdaGetOffer;
    const includeCoappIfSpouseOfWisconsinApp = (externalResourcesState as Resources)?.toggles?.toggles
        ?.includeCoappIfSpouseOfWisconsinApp;
    const parentAppName = params.parentAppName;

    const handleDecisionRoute = useCallback(() => {
        history.push(PAGES['/decision'].urlPath);
    }, [history]);

    if (emptyString(correlationId)) throw Error('Application cannot be sent without x-coxauto-correlation-id'); // go to Oh No Page
    const dealerId = params.dealerId;
    if (emptyString(dealerId)) throw Error('Application cannot be sent without partyId(dealerId)'); // go to Oh No Page

    const leadRoutingOverrideEnabled = params.leadRoutingOverrideEnabled;
    const dealertrackOverrideId = sanitizeStrNum(params.dealertrackOverrideId);
    if (leadRoutingOverrideEnabled && emptyString(dealertrackOverrideId)) {
        throw Error('Application cannot be sent without partyId(dealertrackOverrideId)'); // go to Oh No Page
    }

    const useFnI2 = (externalResourcesState as Resources).dealer?.useFnI2;
    if (useFnI2 === undefined) {
        throw Error('Application cannot be sent without knowing the dealer type Fni 1.0 | 2.0'); // go to Oh No Page
    }
    const headers = {
        'Content-Type': 'application/json'
    };

    const accelerateDealerId = getAccelerateDealerId(
        Number(dealerId),
        (externalResourcesState as Resources).dealer.creditProvider,
        Boolean(leadRoutingOverrideEnabled),
        Number(dealertrackOverrideId)
    );

    let extraData: any;

    if ((externalResourcesState as Resources).experience === CreditAppExperiences.Standalone || isStandaloneExperience) {
        extraData = {
            dealer: {
                useFnI2: (externalResourcesState as Resources).dealer.useFnI2,
                id: accelerateDealerId,
                leadRoutingOverrideEnabled,
                dealertrackOverrideId,
                creditProvider: (externalResourcesState as Resources).dealer.creditProvider,
                routeOnePartyId: (externalResourcesState as Resources).dealer.routeOnePartyId,
                isDueAtSigninAmountDeduct: (externalResourcesState as Resources).dealer.isDueAtSigninAmountDeduct,
                isTargetPlatformR1J: (externalResourcesState as Resources).dealer.isTargetPlatformR1J,
                dnaAccountId: (externalResourcesState as Resources).dealer.dnaAccountId,
                leadRoutingOverrideSettings: (externalResourcesState as Resources).dealer.leadRoutingOverrideSettings
            },
            'x-coxauto-correlation-id': correlationId,
            'coxauto:ci-id': componentId,
            experience: CreditAppExperiences.Standalone,
            toggles: {
                enableCreditAppInfoRecord,
                toggleFsDealRefId,
                enableSponsorQueryParam,
                enableLoggingDecisionPayload,
                enableLambdaGetOffer,
                includeCoappIfSpouseOfWisconsinApp,
                isAioDxgScaLeadEnabled
            }
        };
    } else {
        extraData = {
            dealer: {
                useFnI2: (externalResourcesState as Resources).dealer.useFnI2,
                id: accelerateDealerId,
                creditProvider: (externalResourcesState as Resources).dealer.creditProvider,
                routeOnePartyId: (externalResourcesState as Resources).dealer.routeOnePartyId,
                leadRoutingOverrideEnabled,
                dealertrackOverrideId,
                isDueAtSigninAmountDeduct: (externalResourcesState as Resources).dealer.isDueAtSigninAmountDeduct,
                isTargetPlatformR1J: (externalResourcesState as Resources).dealer.isTargetPlatformR1J,
                isAccelerateCreditDecisionEnabled: (externalResourcesState as Resources).dealer.isAccelerateCreditDecisionEnabled,
                leadRoutingOverrideSettings: (externalResourcesState as Resources).dealer.leadRoutingOverrideSettings
            },
            'x-coxauto-correlation-id': correlationId,
            experience: CreditAppExperiences.Embedded,
            toggles: {
                enableCreditAppInfoRecord,
                toggleFsDealRefId,
                enableSponsorQueryParam,
                enableLoggingDecisionPayload,
                includeCoappIfSpouseOfWisconsinApp,
                enableCreditDecisionDetails
            },
            sessionId: params.sessionId,
            connectionId,
            vin,
            vehicleCondition,
            dealXgId,
            parentAppName
        };
    }

    if (params.source) {
        extraData.source = params.source;
    }

    if (params.sponsor) {
        extraData.sponsor = params.sponsor;
    }

    const [canWaitForDealXgId, setCanWaitForDealXgId] = useState(true);

    if (isEmbeddedExperience && !dealXgId && !canWaitForDealXgId) {
        throw Error('Application cannot be sent without dealXgId'); // go to Oh No Page
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setCanWaitForDealXgId(false);
        }, DEAL_XG_WAIT_TIME);

        return () => clearTimeout(timer);
    }, []);

    const [{ data, loading, error }, executeSubmit] = useAxios(
        {
            url: getLambdaUrl(),
            method: 'POST',
            headers,
            data: {
                application: generatePayload(creditAppState, extraData),
                extraData
            }
        },
        { manual: true }
    );

    useEffect(() => {
        // dr-services-credit-app requires having dealXgId in embedded experience
        if (isStandaloneExperience || dealXgId) {
            executeSubmit().catch((error) => console.error('Application Submission Failed', error));
        }
    }, [isStandaloneExperience, dealXgId, executeSubmit]);

    useEffect(() => {
        const {
            primaryApplicant: { firstName, lastName, phone, email }
        } = creditAppState.personalInfo;
        if (data?.body?.result) {
            const { creditAppId, dealRefId, fsDealRefId, shadowCreditAppId } = data.body.result;
            if (!emptyString(creditAppId)) {
                if (isEmbeddedExperience) {
                    sendAdobeEvents(ADOBE_TAGS.DR_CREDIT_APP_SUBMITTED);
                } else {
                    if (creditAppId) constructSCADataLayer(ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_SUBMITTED);
                }
                // Dispatching pixal analytics post creditApp successful submission.
                sendLeadAndCreditApp({
                    shopper: {
                        firstName: firstName.getTrimmed(),
                        lastName: lastName.getTrimmed(),
                        phone: phone.getTrimmed(),
                        email: email.getTrimmed()
                    },
                    creditAppId,
                    dealRefId, // TODO: clan up dealRefId
                    fsDealRefId
                });
            }
            // TODO: Not bundle this logic together
            if (isCreditDecisionEnabled && isEmbeddedExperience) {
                creditAppState.creditDecision.creditAppId = creditAppId;
                creditAppState.creditDecision.dealRefId = dealRefId; // TODO: clan up dealRefId
                creditAppState.creditDecision.fsDealRefId = fsDealRefId;
                creditAppState.creditDecision.shadowCreditAppId = shadowCreditAppId;
                sendAccelerateCreditAppId(creditAppState.creditDecision.shadowCreditAppId);
                sendAccelerateInfoRecordUpdateStatus();
                handleDecisionRoute();
            } else if (isEmbeddedExperience) {
                sendCreditAppId(data.body.result.creditAppId);
                sendAccelerateCreditAppId(creditAppState.creditDecision.shadowCreditAppId);
                sendAccelerateInfoRecordUpdateStatus();
            }
        }
    }, [
        canWaitForDealXgId,
        creditAppState.creditDecision,
        creditAppState.creditDecision.creditAppId,
        creditAppState.creditDecision.dealRefId,
        creditAppState.creditDecision.fsDealRefId,
        creditAppState.creditDecision.shadowCreditAppId,
        creditAppState.personalInfo,
        data,
        handleDecisionRoute,
        isCreditDecisionEnabled,
        isEmbeddedExperience
    ]);

    if (error) {
        const body = error.response?.data;
        const fsDealRefId = body?.dealRefId ?? body?.fsDealRefId; // TODO: clan up dealRefId

        if (error.isAxiosError) {
            console.error('Application Submission Failed - dealRefId: ', fsDealRefId);
        }
        throw new Error('Submission Failed');
    }
    const creditAppId = data?.body?.result?.creditAppId;
    const isWaitingDxgId = isEmbeddedExperience && !dealXgId && canWaitForDealXgId;
    if (loading || isWaitingDxgId) {
        return (
            <SpinnerContainer data-testid="confirmation-spinner">
                <Spinner size={60} />
            </SpinnerContainer>
        );
    } else {
        // If Embedded SP renders the confirmation page
        if (isEmbedded) {
            // at this point, we are in AMD (non-decision) submission success
            // we return NULL because the credit activity needs to redirect to the default
            // success route to show the confirmation screen
            return null;
        }
        const ConfirmationContainerWithPageUI = WithPageUI(ConfirmationContainer, { creditAppId });
        return (
            <PageContainer>
                <ConfirmationContainerWithPageUI />
            </PageContainer>
        );
    }
};

export default ConfirmationPage;
