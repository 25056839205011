import React from 'react';

import { CheckboxWrapper, Column, Row } from '../../components/shared.styled';
import { TitleWrapper } from '../../components/shared.styled';
import { YearMonthPicker } from '../../components/yearMonthPicker/YearMonthPicker';
import { CheckboxFormField, InputFormField, SelectFormField } from '../../formFields';
import ZipCodeFormField from '../../formFields/ZipCodeFormField';
import { OwnershipTypes } from '../../utils/selectOptions';
import { AddressDispatcher } from './Address.Dispatcher';
import { HousingDispatcher } from './Housing.dispatcher';
import { Housing } from './models/Housing.model';
import PreviousAddressInfoForm from './PreviousHousingInfoForm';

interface HousingInfoFormProps {
    title: string;
    housing: Housing;
    housingDispatcher: HousingDispatcher;
    addressDispatcher: AddressDispatcher;
    previousAddressDispatcher: AddressDispatcher;
    yearMonthPickerErrorMessage: string;
    previousYearMonthPickerErrorMessage: string;
    isJoint?: boolean;
    isPrimary?: boolean;
    className?: string;
    applicantAge?: number;
    zipStateErrorMessage?: string;
}

const HousingInfoForm: React.FC<HousingInfoFormProps> = ({
    title,
    housing,
    housingDispatcher,
    addressDispatcher,
    previousAddressDispatcher,
    yearMonthPickerErrorMessage,
    previousYearMonthPickerErrorMessage,
    isJoint,
    isPrimary,
    className,
    applicantAge,
    zipStateErrorMessage
}) => {
    const { monthlyPayment, address, previousAddress, ownOrRent, addressSameAsPrimaryApplicant } = housing;

    /** address is a typeof the Address class in the housing model  */
    const { streetAddress, city, state, zip, suiteAptNumber, yearsAtAddress, monthsAtAddress } = address;
    const preStringId = isPrimary ? 'applicant' : 'co-applicant';
    const isOwnOutright = ownOrRent.value === OwnershipTypes['Own Outright'];
    return (
        <div className={className}>
            {
                <>
                    {isJoint && <TitleWrapper>{title}</TitleWrapper>}
                    {!isPrimary && (
                        <CheckboxWrapper>
                            <CheckboxFormField
                                formField={addressSameAsPrimaryApplicant}
                                onChangeHandler={housingDispatcher.updateCheckboxValue}
                            />
                        </CheckboxWrapper>
                    )}
                </>
            }
            <Row>
                <Column>
                    <SelectFormField formField={ownOrRent} onChangeHandler={housingDispatcher.updateOwnershipOptionStatus} />
                </Column>
                <Column>
                    {!isOwnOutright && (
                        <InputFormField
                            formField={monthlyPayment}
                            onChangeHandler={(event) => {
                                housingDispatcher.updateInfo(event);
                            }}
                        />
                    )}
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputFormField formField={streetAddress} onChangeHandler={addressDispatcher.updateAddressInfo} />
                </Column>
                <Column>
                    <InputFormField formField={suiteAptNumber} onChangeHandler={addressDispatcher.updateAddressInfo} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputFormField formField={city} onChangeHandler={addressDispatcher.updateAddressInfo} />
                </Column>
                <Column>
                    <SelectFormField formField={state} onChangeHandler={addressDispatcher.updateAddressInfo} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <ZipCodeFormField
                        formField={zip}
                        applicantAge={applicantAge}
                        errorMessage={zipStateErrorMessage}
                        onChangeHandler={housingDispatcher.updateZip}
                    />
                </Column>
                <Column>
                    <YearMonthPicker
                        label="How Long Have You Lived Here?"
                        year={yearsAtAddress}
                        month={monthsAtAddress}
                        monthChangeHandler={housingDispatcher.renderHousingHiddenFieldsMonths}
                        yearChangeHandler={housingDispatcher.renderHousingHiddenFieldsYear}
                        errorMessage={yearMonthPickerErrorMessage}
                    />
                </Column>
            </Row>

            {/* This previous address section does not render unless it exist. */}
            {previousAddress && (
                <PreviousAddressInfoForm
                    previousAddress={previousAddress}
                    addressDispatcher={previousAddressDispatcher}
                    previousYearMonthPickerErrorMessage={previousYearMonthPickerErrorMessage}
                    preStringId={preStringId}
                />
            )}
        </div>
    );
};

export default HousingInfoForm;
